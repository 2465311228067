import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'

import {
  BookingsResponseType, IAvailability, IUserDetails, UpdateBookingRequestType
} from '@frontend-components/types'
import { bookingUrl, authUserType } from '@constants'

export const BOOKING_API_REDUCER_KEY = 'bookingApi'

export const bookingApi = createApi({
  reducerPath: BOOKING_API_REDUCER_KEY,
  tagTypes: ['BOOKINGS'],
  baseQuery: baseQueryWithAuth({ baseUrl: bookingUrl }),
  endpoints: (builder) => ({
    getBookings: builder.query<BookingsResponseType, { userId: IUserDetails['id'], statuses?: string }>({
      query: ({ userId, statuses }) => ({
        url: `/api/bookings?user_id=${userId}${statuses != null ? `&status=${statuses}` : ''}`,
        method: 'GET',
        headers: { [authUserType]: 'user' }
      }),
      providesTags: ['BOOKINGS']
    }),
    putBooking: builder.mutation<IAvailability, UpdateBookingRequestType>({
      query: ({
        bookingId, payload
      }) => ({
        url: `/api/bookings/${bookingId}`,
        method: 'PUT',
        body: payload,
        headers: { [authUserType]: 'user' }
      }),
      invalidatesTags: ['BOOKINGS']
    }),
    getBookingPaymentLink: builder.query<unknown, number>({
      query: (id) => ({
        url: `/api/bookings/${id}/payment`,
        method: 'GET',
        headers: { [authUserType]: 'user' }
      })
    })
  })
})

export const {
  useGetBookingsQuery,
  useLazyGetBookingPaymentLinkQuery,
  usePutBookingMutation
} = bookingApi
