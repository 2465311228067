import { FC } from 'react'

import { ThemeProvider, createTheme } from '@mui/material'

import { MuiThemeProviderProps } from './MuiThemeProvider.types'
// @ts-ignore
import { themeColors } from '@testavivadk/css-tools/css/themeColors'

const MuiThemeProvider: FC<MuiThemeProviderProps> = ({ children }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: themeColors.darkerTeal
      },
      secondary: {
        main: themeColors.warmPink
      }
    },
    components: {
      MuiAutocomplete: {
        defaultProps: {
          componentsProps: { paper: { sx: { minWidth: 'min-content' } } }
        }
      },
      MuiButton: {
        defaultProps: {
          sx: {
            textTransform: 'none'
          }
        }
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

export default MuiThemeProvider
