import { RootState } from '@store/rootReducer'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { setCartSidebarOpen } from '@store/slices/cartSlice'
import { useTranslation } from 'react-i18next'

import {
  Box, Drawer, Button, useMediaQuery
} from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import CartIframe from './components/CartIframe/CartIframe'

const CartSidebar = () => {
  const dispatch = useDispatch()
  const { isCartSidebarOpen } = useSelector((state: RootState) => ({ isCartSidebarOpen: state.cart.isCartSidebarOpen }), shallowEqual)
  const isMinWidth30rem = useMediaQuery('(min-width:30rem)')
  const { t } = useTranslation()

  const handleClose = () => {
    dispatch(setCartSidebarOpen(false))
  }

  return (
    <Drawer open={isCartSidebarOpen} onClose={handleClose} anchor="right">
      <Box sx={{
        width: isMinWidth30rem ? '30rem' : '90vw',
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        gap: 2
      }}
      >
        <Box>
          <Button startIcon={<KeyboardBackspaceIcon />} variant="contained" onClick={handleClose}>{t('cartSidebar.backButton')}</Button>
        </Box>
        <CartIframe />
      </Box>
    </Drawer>
  )
}
export default CartSidebar
