import { useState } from 'react'

import { useGetUserDetailsQuery } from '@store/services/user'

import IframeResizer from 'iframe-resizer-react'
import MuiLoadingContainer from '@components/MuiLoadingContainer'

import { ecommerceUrl } from '@constants'

const CartIframe = () => {
  const { data: userDetailsData } = useGetUserDetailsQuery()
  const [isIframeLoading, setIframeLoading] = useState(true)

  if (userDetailsData?.id == null) return <MuiLoadingContainer />

  const iframeUrl = `${ecommerceUrl}/cart?user-id=${userDetailsData.id}`

  return (
    <>
      {isIframeLoading && <MuiLoadingContainer />}
      <IframeResizer
        name="cart"
        src={iframeUrl}
        onLoad={() => setIframeLoading(false)}
        style={{
          border: 'none', minWidth: '100%', minHeight: '100%', paddingBottom: '2rem', display: isIframeLoading ? 'none' : 'block'
        }}
      />
    </>
  )
}

export default CartIframe
