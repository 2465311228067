import { FC, useEffect, useState } from 'react'

import { useGetBasketQuery } from '@store/services/basket'
import { useTranslation } from 'react-i18next'
import { useGetUserDetailsQuery } from '@store/services/user'
import { useFlag } from '@unleash/proxy-client-react'
import { useDispatch } from 'react-redux'
import { setPurchaseSidebarOpen } from '@store/slices/basketSlice'
import { setCartSidebarOpen } from '@store/slices/cartSlice'

import { Box, Button } from '@mui/material'
import { ShoppingCart } from '@mui/icons-material'
import { themeColors } from '@testavivadk/css-tools/css/themeColors'
import { ecommerceUrl } from '@constants'

const CartButton: FC = () => {
  const { data: userDetailsData } = useGetUserDetailsQuery()
  const { data: basketData } = useGetBasketQuery(null)
  const [cartQuantity, setCartQuantity] = useState(0)

  const userId = userDetailsData?.id

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isEcommerceUserCartEnabled = useFlag('EcommerceUserCart')

  const handleClickBasket = () => {
    if (isEcommerceUserCartEnabled) {
      dispatch(setCartSidebarOpen(true))
      return
    }
    dispatch(setPurchaseSidebarOpen(true))
  }

  const isCartHasItems = isEcommerceUserCartEnabled ? cartQuantity > 0 : (basketData?.items.length ?? 0) > 0

  useEffect(() => {
    const fetchCartItems = async () => {
      const response = await fetch(`${ecommerceUrl}/api/v1/users/${userId}/cart/items`)
      const { items }: { items: number } = await response.json()
      setCartQuantity(items)
    }
    fetchCartItems()
  }, [])

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const fetchCartItems = async () => {
        const response = await fetch(`${ecommerceUrl}/api/v1/users/${userId}/cart/items`)
        const { items }: { items: number } = await response.json()
        setCartQuantity(items)
      }

      if (event.data === 'updateCart') {
        fetchCartItems()
      }
      if (event.data === 'addProduct') {
        fetchCartItems()
        dispatch(setCartSidebarOpen(true))
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  return (
    <Button
      variant="contained"
      color="secondary"
      sx={{
        height: '100%',
        borderRadius: 0
      }}
      id="toggle-basket"
      onClick={handleClickBasket}
      title={t('basket.buttonTitle') as string}
      data-testid="purchase-sidebar-toggle-button"
    >
      {isCartHasItems ? (
        <Box
          component="span"
          sx={{
            position: 'absolute',
            backgroundColor: 'white',
            borderRadius: '50%',
            color: themeColors.warmPink,
            width: '14px',
            top: '3px',
            right: '11px',
            fontSize: '0.625rem',
            height: '14px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold'
          }}
        >
          {isEcommerceUserCartEnabled ? cartQuantity : basketData?.items.length}
        </Box>
      ) : null}
      <ShoppingCart />
    </Button>
  )
}

export default CartButton
