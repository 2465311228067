import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'

const fetchResources = async () => {
  const loadPath = 'https://api.i18nexus.com/project_resources/translations/da/default.json?api_key=yN_0dQPzPibsDUGMc30d7g'
  const spaResources = (await fetch(loadPath)).json()

  const globalLoadPath = 'https://api.i18nexus.com/project_resources/translations/da/default.json?api_key=Y1rOfKm7xtIxVYtoNc91Lg'
  const globalResources = (await fetch(globalLoadPath)).json()

  const response = await Promise.all([spaResources, globalResources])
  const resources = {}
  response.forEach((resource) => {
    Object.assign(resources, resource)
  })

  return resources
}

const backendOptions = {
  request: (_options: unknown, _url: unknown, _payload: unknown, callback: any) => {
    try {
      fetchResources().then((response) => {
        callback(null, {
          data: response,
          status: 200
        })
      })
    } catch (e) {
      callback(null, { status: 500 })
    }
  }
}

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init({
    interpolation: { escapeValue: false },
    backend: backendOptions
  })

export default i18n
