import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SubmitActions, AuthenticationSteps } from '@frontend-components/features/Authentication'

export interface ModalState {
  isPartnersModalOpen: boolean
  identityVerificationModalState: {
    isOpen: boolean,
    redirectUrl?: string
  }
  partnerModalIdentifier: string | null
  authenticationModal: {
    step?: AuthenticationSteps
    onSubmitCallback?: (response: Response, key: SubmitActions) => void
    isEmailVerificationRequired?: boolean
  } | null,
  downloadBlobData?: string
}

const initialState: ModalState = {
  isPartnersModalOpen: false,
  identityVerificationModalState: {
    isOpen: false,
    redirectUrl: undefined
  },
  partnerModalIdentifier: null,
  authenticationModal: null,
  downloadBlobData: undefined
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setPartnersModalOpen(state, action: PayloadAction<{ isOpen: boolean, identifier?: string }>) {
      state.isPartnersModalOpen = action.payload.isOpen
      if (action.payload.identifier == null) {
        state.partnerModalIdentifier = null
      } else {
        state.partnerModalIdentifier = action.payload.identifier
      }
    },
    setIdentityVerificationModalState(state, action: PayloadAction<ModalState['identityVerificationModalState']>) {
      state.identityVerificationModalState = { ...action.payload }
    },
    setAuthenticationModalData(state, action: PayloadAction<ModalState['authenticationModal']>) {
      state.authenticationModal = action.payload
    },
    setDownloadBlobModal(state, action: PayloadAction<string | undefined>) {
      state.downloadBlobData = action.payload
    }
  }
})

export const {
  setPartnersModalOpen,
  setIdentityVerificationModalState,
  setAuthenticationModalData,
  setDownloadBlobModal
} = modalSlice.actions
export default modalSlice.reducer
