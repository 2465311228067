import {
  FC,
  lazy,
  Suspense,
  useCallback,
  useMemo,
  useEffect
} from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { RootState } from '@store/rootReducer'
import { useGetProductPricesQuery, useGetUserRecommendedProductsQuery } from '@store/services/products'
import { usePrevious } from '@frontend-components/hooks/usePrevious'
import {
  useGetBasketPartnerTextQuery,
  useGetBasketQuery,
  usePostBasketItemMutation,
  usePutBasketItemMutation,
  useDeleteBasketItemMutation
} from '@store/services/basket'
import { useGetUserDetailsQuery } from '@store/services/user'
import { setPurchaseSidebarOpen } from '@store/slices/basketSlice'
import { useGetTasksQuery } from '@store/services/tasks'

import { BasketChangeEvents } from '@frontend-components/features/PurchaseSidebar'

import { applicationUrl, paymentUrl } from '@constants'
import { applicationSpaRoutes } from '@frontend-components/constants'
import { IBasketItem } from '@frontend-components/types'

const PurchaseSidebarFeature = lazy(() => import(/* webpackChunkName: "PurchaseSidebarComponent" */'@frontend-components/features/PurchaseSidebar'))

const pagesToExcludeSidebar = [applicationSpaRoutes.basket, applicationSpaRoutes.receipt]

const PurchaseSidebar: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const prevLocation = usePrevious(location.pathname)
  const isPurchaseSidebarDisabled = pagesToExcludeSidebar.includes(location.pathname)

  const { isPurchaseSidebarOpen } = useSelector((state: RootState) => ({ isPurchaseSidebarOpen: state.basket.isPurchaseSidebarOpen }), shallowEqual)

  const { data: userDetails, isError: isUserDetailsError } = useGetUserDetailsQuery()
  const skipSidebarFetchRequests = !userDetails?.authenticated || !isPurchaseSidebarOpen || isPurchaseSidebarDisabled
  const { data: recommendedProducts } = useGetUserRecommendedProductsQuery(null, { skip: skipSidebarFetchRequests })
  const userPartner = userDetails?.partners?.[0]?.id ?? null
  const { data: priceSet } = useGetProductPricesQuery(userPartner, { skip: skipSidebarFetchRequests })
  const { data: basketData, isFetching: isBasketFetching } = useGetBasketQuery(null, { skip: userDetails?.authenticated !== true || isPurchaseSidebarDisabled })
  const { data: basketPartnerText } = useGetBasketPartnerTextQuery(null, { skip: skipSidebarFetchRequests || userDetails?.onboardingPartner == null || userDetails.onboardingPartner.name == null })
  const { refetch: refetchTasks } = useGetTasksQuery(userDetails?.id ?? 0)

  const [postBasket, { isLoading: isPostBasketLoading }] = usePostBasketItemMutation()
  const [putBasket, { isLoading: isPutBasketLoading }] = usePutBasketItemMutation()
  const [deleteBasket, { isLoading: isDeleteBasketLoading }] = useDeleteBasketItemMutation()

  const isBasketSpinnerVisible = isPostBasketLoading || isPutBasketLoading || isDeleteBasketLoading || isBasketFetching

  const filteredRecommendedProducts = useMemo(() => {
    if (recommendedProducts == null || basketData == null) {
      return []
    }
    let products: IBasketItem['product'][] = [...recommendedProducts]
    basketData.items.forEach((item) => {
      if (recommendedProducts.includes(item.product)) {
        products = products.filter((product) => product !== item.product)
      }
    })

    return products
  }, [recommendedProducts, basketData, skipSidebarFetchRequests])

  const handleChangeBasket = async (event: BasketChangeEvents, item: Partial<IBasketItem>) => {
    const { product, id } = item

    if (event === BasketChangeEvents.ADD) {
      if (product != null && item.purchase_type != null) {
        await postBasket(userDetails?.onboardingPartner != null ? {
          product, purchase_type: item.purchase_type, partner: userDetails.onboardingPartner.id
        } : {
          product, purchase_type: item.purchase_type
        })
      }
    } else if (event === BasketChangeEvents.EDIT) {
      if (id != null && item.purchase_type != null) {
        await putBasket({ id, purchase_type: item.purchase_type })
      }
    } else if (event === BasketChangeEvents.DELETE) {
      if (id != null) {
        await deleteBasket(id)
      }
    }
    refetchTasks()
  }

  const onSidebarOpen = useCallback(() => {
    dispatch(setPurchaseSidebarOpen(true))
  }, [])

  const onSidebarClose = useCallback(() => {
    dispatch(setPurchaseSidebarOpen(false))
  }, [])

  const handleClickGoToBasket = () => {
    dispatch(setPurchaseSidebarOpen(false))
    navigate(applicationSpaRoutes.basket)
  }

  useEffect(() => {
    if (prevLocation !== location.pathname && isPurchaseSidebarOpen) {
      dispatch(setPurchaseSidebarOpen(false))
    }
  }, [prevLocation, location, isPurchaseSidebarOpen])

  if (isUserDetailsError || !userDetails?.authenticated || pagesToExcludeSidebar.includes(location.pathname)) {
    return null
  }

  return (
    <Suspense fallback="">
      <PurchaseSidebarFeature
        user={userDetails}
        basket={basketData}
        priceSet={priceSet}
        isLoading={isBasketSpinnerVisible}
        open={isPurchaseSidebarOpen}
        onOpen={onSidebarOpen}
        onClose={onSidebarClose}
        onGoToBasketClick={handleClickGoToBasket}
        recommendedProductsData={filteredRecommendedProducts}
        onBasketChange={handleChangeBasket}
        serviceUrls={{ applicationUrl, paymentUrl }}
        onboardingPartnerText={basketPartnerText}
        drawerExtraProps={{
          overlay: { 'data-testid': 'purchase-sidebar-overlay' },
          wrapper: { 'data-testid': 'purchase-sidebar-wrapper' }
        }}
      />
    </Suspense>
  )
}

export default PurchaseSidebar
