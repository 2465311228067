import { configureStore } from '@reduxjs/toolkit'
import { userApi } from '@store/services/user'
import { productsApi } from '@store/services/products'
import { basketApi } from '@store/services/basket'
import { notificationsApi } from '@store/services/notifications'
import { partnerApi } from '@store/services/partner'
import { paymentApi } from '@store/services/payment'
import { onboardingApi } from '@store/services/onboarding'
import { documentsApi } from '@store/services/documents'
import { identificationFilesApi } from '@store/services/identificationFiles'
import { identificationFilesNonUserApi } from '@store/services/identificationFilesNonUser'
import { taskApi } from './services/tasks'
import { bookingApi } from './services/booking'

import rootReducer from './rootReducer'

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat([
    userApi.middleware,
    productsApi.middleware,
    basketApi.middleware,
    notificationsApi.middleware,
    partnerApi.middleware,
    paymentApi.middleware,
    onboardingApi.middleware,
    documentsApi.middleware,
    identificationFilesApi.middleware,
    identificationFilesNonUserApi.middleware,
    taskApi.middleware,
    bookingApi.middleware
  ])
})

export type AppDispatch = typeof store.dispatch

export default store
